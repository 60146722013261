<div class="location-contact-section">
  <div class="container" style="margin-bottom: 105px;">
    <h2 style="border-bottom: 2px solid #ac8053; padding-bottom: 10px">À propos de Touda Fournitures</h2>
    <div class="row mt-4">
      <div class="col-md-6 text-left">
        <p>
          Depuis 2005, l'entreprise Touda Fournitures s'est spécialisé dans la fourniture de
          produits de confection et textiles. Basés à Tanger, nous sommes fiers
          de notre expertise et de notre engagement envers la qualité.
        </p>
        <p>
          Notre engagement envers la qualité se reflète dans chaque produit que
          nous fournissons. Que ce soit des dentelles, des guipures, des fils à
          coudre ou d'autres matériaux, nous mettons l'accent sur l'excellence.
        </p>
      </div>
      <div class="col-md-6 text-right">
        <p>
          منذ عام 2005، اختصت شركة تودى للمستلزمات في توريد منتجات الخياطة والمنسوجات. مقرنا في طنجة، ونحن فخورون بخبرتنا والتزامنا بالجودة
        </p>
        <p>
          يتجلى التزامنا بالجودة في كل منتج نقدمه، سواء كانت الدانتيل، الجوبير، خيوط الخياطة أو غيرها من المواد
        </p>
      </div>
    </div>
  </div>
</div>

