import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css'],
})
export class ItemsComponent implements OnInit {
  categories = [
    {
      id: 'fil-a-coudre',
      name: 'Fil / الخيط',
      url: './../../assets//fil_coudre.png',
      items: [
        {
          name: 'خيط الخياطة',
          url: './../../assets/fil/fil-a-coudre.png',
        },
        {
          name: 'خيط الطرز',
          url: './../../assets/fil/fil-a-broder.png',
        },
        {
          name: 'خيط السورجي',
          url: './../../assets/fil/fil-file.png',
        },
        {
          name: 'خيط الصقلي',
          url: './../../assets/fil/fil-metalique.png',
        },
      ],
    },
    {
      id: 'bandes-elastiques',
      name: 'Bandes Elastiques / الاستيك',
      url: './../../assets/bandes_elastiques.png',
      items: [
        {
          name: '(1.5 cm - 4 cm) جبادا',
          url: './../../assets/elastique/jbada.png',
        },
        {
          name: '(6 mm - 12 mm) الاستيك رولو',
          url: './../../assets/elastique/elastique-rolo.png',
        },
        {
          name: 'الاستيك الملابس الداخلية',
          url: './../../assets/elastique/chkikat.png',
        },
        {
          name: 'الاستيك الملابس الداخلية',
          url: './../../assets/elastique/elastique-normal.png',
        },
        {
          name: 'الاستيك الملابس الداخلية',
          url: './../../assets/elastique/sninat1.png',
        },
        {
          name: 'الاستيك الملابس الداخلية',
          url: './../../assets/elastique/sninat.png',
        },
        {
          name: 'الاستيك الملابس الداخلية',
          url: './../../assets/elastique/princessa.png',
        },
        {
          name: 'الاستيك الملابس الداخلية',
          url: './../../assets/elastique/hadaba.png',
        },
      ],
    },
    {
      id: 'rubans',
      name: 'Rubans / السنتات',
      url: './../../assets/rubans.png',
      items: [
        {
          name: '(7 mm - 2,5 cm) سنتا ستان',
          url: './../../assets/ribbon/satin.png',
        },
        {
          name: '(7 mm - 2,5 cm) سنتا مشرفة',
          url: './../../assets/ribbon/mcharfa.png',
        },
        {
          name: '(7 mm - 2,5 cm) سنتا أوركنزا',
          url: './../../assets/ribbon/organza.png',
        },
        {
          name: '(1 cm - 2 cm) سنتا مبرة',
          url: './../../assets/ribbon/mobra.png',
        },
        {
          name: '(1 cm - 2,5cm) سنتا مشرطة',
          url: './../../assets/ribbon/mcharta.png',
        },
        {
          name: '(7 mm - 2 cm) لاصو',
          url: './../../assets/ribbon/lasso.png',
        },
      ],
    },
    {
      id: 'ciseaux',
      name: 'Ciseaux / المقص',
      url: './../../assets/ciseaux.png',
      items: [
        {
          name: '(8 - 12) مقص فصالة',
          url: './../../assets/ciseau/fssala.png',
        },
        {
          name: '(K19,K20) مقص المكتب',
          url: './../../assets/ciseau/bureau.png',
        },
        {
          name: 'مقص نقى',
          url: './../../assets/ciseau/nka.png',
        },
        {
          name: 'مقص نقى',
          url: './../../assets/ciseau/nka_hdid.png',
        },
        {
          name: 'مقص نقى',
          url: './../../assets/ciseau/nka_pin.png',
        },
      ],
    },
    {
      id: 'soutien-gorge',
      name: 'Fournitures de soutien-gorge',
      url: './../../assets/soutien_gorge.png',
      items: [
        {
          name: 'كروشي',
          url: './../../assets/soutien/crochet.png',
        },
        {
          name: '(15 mm) انو أبيض',
          url: './../../assets/soutien/ano_blanc.png',
        },
        {
          name: '(8 mm - 15 mm) انو شفاف',
          url: './../../assets/soutien/chfaf.png',
        },
      ],
    },
    {
      id: 'buttons',
      name: 'Boutons / الصداف',
      url: './../../assets/buttons.png',
      items: [
        {
          name: 'صداف جوهرة',
          url: './../../assets/bouton/perle.png',
        },
        {
          name: 'صداف جوهرة',
          url: './../../assets/bouton/perle1.png',
        },
        {
          name: 'صداف كريستال',
          url: './../../assets/bouton/crystal.png',
        },
        {
          name: 'صداف كريستال',
          url: './../../assets/bouton/crystal1.png',
        },
        {
          name: 'صداف كريستال',
          url: './../../assets/bouton/crystal2.png',
        },
        {
          name: 'صداف نقري',
          url: './../../assets/bouton/silver.png',
        },
        {
          name: 'صداف نقري',
          url: './../../assets/bouton/silver2.png',
        },
        {
          name: 'صداف دهبي',
          url: './../../assets/bouton/gold.png',
        },
        {
          name: 'صداف دهبي',
          url: './../../assets/bouton/gold1.png',
        },
        {
          name: 'صداف حسبي',
          url: './../../assets/bouton/transparent.png',
        },
        {
          name: 'صداف حسبي',
          url: './../../assets/bouton/simple.png',
        },
        {
          name: 'صداف حسبي',
          url: './../../assets/bouton/simple1.png',
        },
        {
          name: 'صداف حسبي',
          url: './../../assets/bouton/simple2.png',
        }
      ],
    },
    {
      id: 'biais',
      name: 'Biais / البيي ',
      url: './../../assets/biais.png',
      items: [
        {
          name: 'بيي سطان',
          url: './../../assets/biais/satin.png',
        },
        {
          name: 'بيي كردون',
          url: './../../assets/biais/cordon.png',
        },
      ],
    },
    {
      id: 'fermetures',
      name: 'Fermeture éclair / السنسلة',
      url: './../../assets/fermetures.png',
      items: [
        {
          name: 'سنسلة عادية',
          url: './../../assets/fermetures/simple.png',
        },
        {
          name: 'سنسلة مدفونة',
          url: './../../assets/fermetures/invisible.png',
        },
        {
          name: 'سنسلة حديد',
          url: './../../assets/fermetures/hdid.png',
        }
      ],
    },
    {
      id: 'rubans-adhesifs',
      name: 'Rubans adhésifs / السكوتش',
      url: './../../assets/rubans_adhesifs.png',
      items: [
        {
          name: 'سكوتش كبير',
          url: './../../assets/adhesive/grand.png',
        },
        {
          name: 'سكوتش صغير',
          url: './../../assets/adhesive/petit.png',
        }
      ],
    },
    {
      id: 'etiquettes',
      name: 'Étiquettes / التيكيات',
      url: './../../assets/etiquettes.png',
      items: [
        {
          name: 'تيكي اللصقة',
          url: './../../assets/etiquettes/lssaka.png',
        },
        {
          name: 'تيكي الثوب',
          url: './../../assets/etiquettes/tisees.png',
        },
        {
          name: 'تيكي تحت الطلب',
          url: './../../assets/etiquettes/custom.png',
        },
        {
          name: 'حبل تيكي',
          url: './../../assets/etiquettes/khayt.png',
        }
      ],
    },
    {
      id: 'velcro',
      name: 'Velcro / الفيلكرو',
      url: './../../assets/velcro.png',
      items: [
        {
          name: 'فيلكرو أبيض',
          url: './../../assets/velcro/white.png',
        },
        {
          name: 'فيلكرو أسود',
          url: './../../assets/velcro/black.png',
        },
      ],
    },
    {
      id: 'aiguilles',
      name: 'Aiguilles / إبر الخياطة',
      url: './../../assets/aiguilles.png',
      items: [
        {
          name: '',
          url: './../../assets/needle/95.png',
        },
        {
          name: '',
          url: './../../assets/needle/96.png',
        },
        {
          name: '',
          url: './../../assets/needle/97.png',
        },
        {
          name: '',
          url: './../../assets/needle/98.png',
        },
        {
          name: '',
          url: './../../assets/needle/99.png',
        },
        {
          name: '',
          url: './../../assets/needle/100.png',
        }
      ],
    },
    {
      id: 'dentelles',
      name: 'Dentelles / الدانتيل',
      url: './../../assets/dentelles.png',
      items: [
        {
          name: 'دانتيل عريض 17 سم',
          url: './../../assets/lace/17.png',
        },
        {
          name: 'دانتيل موسط 7 سم',
          url: './../../assets/lace/mwsst.png',
        },
        {
          name: 'دانتيل رقيق 3,5 سم',
          url: './../../assets/lace/rkik1.png',
        },
        {
          name: 'دانتيل رقيق 3 سم',
          url: './../../assets/lace/rkik.png',
        },
        {
          name: 'دانتيل رقيق قليبات',
          url: './../../assets/lace/klibat.png',
        },
        {
          name: ' دانتيل أوركانزا ',
          url: './../../assets/lace/organza.png',
        },
        {
          name: 'دانتيل تول',
          url: './../../assets/lace/tul.png',
        }
      ],
    },
    {
      id: 'sachets',
      name: 'Sachets / السلفان',
      url: './../../assets/sachets.png',
      items: [
        {
          name: 'OPP BAG',
          url: './../../assets/bag/opp.png',
        },
        {
          name: 'PVC BAG',
          url: './../../assets/bag/pvc.png',
        },
        {
          name: 'ZIPPER BAG',
          url: './../../assets/bag/zipper.png',
        }
      ],
    },
    {
      id: 'guipure',
      name: 'Guipure / الكيبور',
      url: './../../assets/guipure.png',
      items: [
        {
          name: '',
          url: './../../assets/guipure/89.png',
        },
        {
          name: '',
          url: './../../assets/guipure/90.png',
        },
        {
          name: '',
          url: './../../assets/guipure/91.png',
        },
        {
          name: '',
          url: './../../assets/guipure/92.png',
        },
        {
          name: '',
          url: './../../assets/guipure/93.png',
        },
        {
          name: '',
          url: './../../assets/guipure/94.png',
        }

      ],
    },
  ];
  selectedCategory: any;
  selectedProductName: string = '';
  productName: string = '';

  constructor(private router: Router, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      this.productName = params['productName'];
      if (
        this.categories.filter(
          (category: any) => category.id == this.productName
        ).length > 0
      ) {
        this.selectedCategory = this.categories.filter(
          (category: any) => category.id == this.productName
        )[0];
      } else {
        this.selectedCategory = { name: 'Produit non trouvé', items: [] };
      }
      this.selectedProductName = this.selectedCategory.name;
    });
  }

  ngOnInit(): void {}

  goBack(): void {
    this.router.navigateByUrl('/products');
  }
}
