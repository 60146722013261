<div class="location-contact-section">
  <div class="container">
    <h2 class="location-title" style="border-bottom: 2px solid #ac8053; padding-bottom: 10px">Localisation</h2>
    <div class="row">
      <div class="col-md-6">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.4299634961253!2d-5.799841824636297!3d35.74023537256821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd0b81857e2d69fb%3A0xe1e24372410e378d!2sTOUDA%20FOURNITURES!5e0!3m2!1sen!2sfr!4v1707338793909!5m2!1sen!2sfr"
          width="100%"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div class="col-md-6" style="text-align: right">
        <p>
          نحن موجودون في
          <span style="font-weight: 600"
            >المنطقة الصناعية المجد، شارع الرياض، رقم 667، طنجة 90000،
            المغرب</span
          >. تفضل بزيارتنا لاكتشاف مجموعتنا الواسعة من المنتجات
        </p>
        <p style="font-weight: bold">اتصل بنا</p>
        <p>
          ساعات العمل: من الاثنين إلى السبت، من الساعة 9:00 صباحًا حتى الساعة 13:00 ظهرًا، ومن الساعة 14:00 بعد الظهر حتى الساعة 18:00 مساءً
        </p>

        <p>+212 6 60 72 43 14</p>
      </div>
    </div>
  </div>
</div>
