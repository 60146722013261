<div class="home">
  <div class="hero-container" *ngIf="mobileMode">
    <img src="./../../assets/toudacouv.png" alt="Hero Image" />
  </div>
  <div class="hero-container" *ngIf="!mobileMode">
    <img src="./../../assets/new_couv.jpeg" alt="Hero Image" />
  </div>

  <div class="about-us-container">
    <div class="about-us-content">
      <h2 style="text-align: center">À propos</h2>
      <div class="row">
        <div class="col-md-6 text-left">
          <p>
            Depuis 2005, l'entreprise Touda Fournitures s'est spécialisé dans la fourniture
            de produits de confection et textiles. Basés à Tanger, nous sommes
            fiers de notre expertise et de notre engagement envers la qualité.
          </p>
        </div>
        <div class="col-md-6 text-right" style="text-align: right;">
          <p>
             منذ عام 2005، اختصت شركة تودى للمستلزمات في توريد منتجات الخياطة والمنسوجات. مقرنا في طنجة، ونحن فخورون بخبرتنا والتزامنا بالجودة
          </p>
        </div>
      </div>
      <div style="text-align: center">
        <button class="btn-about" routerLink="/about">En savoir plus</button>
      </div>
    </div>
  </div>

  <div class="products-section">
    <div class="container text-center">
      <h2>Nos Produits</h2>
      <div class="row">
        <div class="col-md-3 col-sm-12">
          <div class="product-item" (click)="goToProduct('fil-a-coudre')">
            <img src="./../../assets/fil_coudre.png" alt="Sachets" />
            <div class="see-more">Voir Plus</div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="product-item" (click)="goToProduct('rubans')">
            <img
              src="./../../assets/rubans.png"
              alt="Sachets en polypropylène"
            />
            <div class="see-more">Voir Plus</div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="product-item" (click)="goToProduct('buttons')">
            <img src="./../../assets/buttons.png" alt="Dentelles" />
            <div class="see-more">Voir Plus</div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12">
          <div class="product-item" (click)="goToProduct('ciseaux')">
            <img src="./../../assets/ciseaux.png" alt="Broderies" />
            <div class="see-more">Voir Plus</div>
          </div>
        </div>
      </div>
      <button class="btn-products" routerLink="/products">Découvrir</button>
    </div>
  </div>

  <div class="import-section">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h3>
            Importation Internationale
            <span style="color: #d5a371">- استيراد دولي</span>
          </h3>
          <p style="text-align: right;margin-top: 30px;">
            تتخصص شركة تودى للمستلزمات في توريد منتجات الخياطة والمنسوجات عالية الجودة. وقد أصبحت الشركة رمزاً للجودة والموثوقية في هذه الصناعة، حيث قامت ببناء علاقات قوية مع موردين مرموقين في الصين وتركيا. منتجاتنا متنوعة ومبتكرة تلبي الاحتياجات المتنوعة والمتطورة باستمرار لعملائنا
          </p>

        </div>
        <div class="col-md-6">
          <img
            src="./../../assets/international.jpeg"
            alt="Importation Internationale"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="import-section">
    <div class="container">
      <div class="row">
        <div class="col-md-6 order-md-1 order-2">
          <img src="./../../assets/client.jpg" alt="Expérience Client" style="border-radius: 20px;" />
        </div>
        <div class="col-md-6 order-md-2 order-1">
          <h3>
            Expérience Client <span style="color: #d5a371">- تجربة الزبون</span>
          </h3>
          <p style="text-align: right;margin-top: 30px;">
            نضع تجربة الزبناء في قلب مهمتنا. نحن نسعى جاهدين لتقديم خدمة
            استثنائية ومنتجات متميزة وتعاون سلس. رضاكم هو أولويتنا
          </p>
          <p>
            Chez Touda Fournitures, nous mettons l'expérience client au cœur de
            notre mission. Nous nous efforçons de fournir un service
            exceptionnel, des produits de qualité supérieure et une
            collaboration transparente. Votre satisfaction est notre priorité.
          </p>
        </div>
      </div>
    </div>
  </div> -->
  <div class="location-contact-section">
    <div class="container">
      <h2 class="location-title">
        Localisation <span style="color: #d5a371">- موقعنا</span>
      </h2>
      <div class="row">
        <div class="col-md-6">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.4299634961253!2d-5.799841824636297!3d35.74023537256821!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd0b81857e2d69fb%3A0xe1e24372410e378d!2sTOUDA%20FOURNITURES!5e0!3m2!1sen!2sfr!4v1707338793909!5m2!1sen!2sfr"
            width="100%"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div class="col-md-6" style="text-align: right">
          <p>
            نحن موجودون في
            <span style="font-weight: 600"
              >المنطقة الصناعية المجد، شارع الرياض، رقم 667، طنجة 90000،
              المغرب</span
            >. تفضل بزيارتنا لاكتشاف مجموعتنا الواسعة من المنتجات
          </p>
          <p style="font-weight: bold">اتصل بنا</p>
          <p>
            ساعات العمل: من الاثنين إلى السبت، من الساعة 9:00 صباحًا حتى الساعة 13:00 ظهرًا، ومن الساعة 14:00 بعد الظهر حتى الساعة 18:00 مساءً
          </p>

          <p>+212 6 60 72 43 14</p>
        </div>
      </div>
    </div>
  </div>
</div>
